<template>
  <div class="entrustChildList">
    <div class="banner"><img src="./img/banner.png" alt="" /></div>
    <div class="body">
      <div class="box">
        <div class="item">
          <div class="item-l">家长姓名</div>
          <div class="item-r">
            <v-input
              v-model="form.parentsName"
              :maxlength="10"
              placeholder="请填写姓名"
            ></v-input>
          </div>
        </div>
        <div class="item">
          <div class="item-l">家长电话</div>
          <div class="item-r">
            <v-input
              type="number"
              v-model="form.parentsPhone"
              :maxlength="11"
              placeholder="请填写电话"
            ></v-input>
          </div>
        </div>
        <div class="item">
          <div class="item-l">孩子姓名</div>
          <div class="item-r">
            <v-input
              v-model="form.childName"
              :maxlength="20"
              placeholder="请填写姓名"
            ></v-input>
          </div>
        </div>
      </div>
      <div class="surebtn" @click="submit">
        <div
          :class="[
            'btn',
            { light: form.parentsName && form.parentsPhone && form.childName },
          ]"
        >
          提交
        </div>
      </div>
    </div>
    <!-- <v-dateTimePicker
      :value="form.startTime"
      :valueShow.sync="actiStartShow"
      :isAuto="true"
      type="time"
      :title="'选择时间'"
      :max-hour="form.endTime ? form.endTime.split(':')[0] : '23'"
      @confirmPicker="confirmActiStart"
    ></v-dateTimePicker>
    <v-dateTimePicker
      :value="form.endTime"
      :isAuto="true"
      type="time"
      :title="'选择时间'"
      :valueShow.sync="actiEndShow"
      @confirmPicker="confirmActiEnd"
      :min-hour="form.startTime ? form.startTime.split(':')[0] : '00'"
    ></v-dateTimePicker> -->
  </div>
</template>

<script>
import { nurseryApplyUrl, getLastOrderUrl } from "./api.js";
const moment = require("moment");

export default {
  name: "entrustChildList",
  data() {
    return {
      scheduleId: "",
      // actiStartShow: false,
      // actiEndShow: false,
      form: {
        childName: "",
        parentsName: "",
        parentsPhone: "",
        // endTime: "",
        // startTime: "",
        // initDate: "00:00",
      },
    };
  },
  // filters: {
  //   dateFormat: function (value) {
  //     if (value) {
  //       return moment(value).format("HH:mm");
  //     }
  //     return moment(new Date()).format("HH:mm");
  //   },
  // },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  async created() {
    this.scheduleId = this.$route.query.scheduleId;
    await this.getLastOrder();
  },
  methods: {
    // toStart() {
    //   this.actiStartShow = true;
    // },
    // toEnd() {
    //   this.actiEndShow = true;
    // },
    // confirmActiStart(value) {
    //   console.log(value);
    //   this.form.startTime = value;
    // },
    // confirmActiEnd(value) {
    //   this.form.endTime = value;
    // },
    // 获取上一次预约信息
    async getLastOrder() {
      let params = {
        userId: this.userId,
      };
      let res = await this.$axios.get(getLastOrderUrl, { params });
      if (res.code === 200) {
        const keys = ["parentsName", "parentsPhone"];
        keys.forEach((key) => {
          this.form[key] = res.data[key];
        });
      }
    },
    async submit() {
      if (
        !(
          this.form.parentsName &&
          this.form.parentsPhone &&
          this.form.childName
        )
      ) {
        return;
      }
      // let startTime =
      //   moment(new Date()).format("YYYY-MM-DD") +
      //   " " +
      //   this.form.startTime +
      //   ":00";
      // let endTime =
      //   moment(new Date()).format("YYYY-MM-DD") +
      //   " " +
      //   this.form.endTime +
      //   ":00";
      let params = {
        parentsName: this.form.parentsName,
        parentsPhone: this.form.parentsPhone,
        childName: this.form.childName,
        scheduleId: this.scheduleId,
        userId: this.userId,
      };
      let res = await this.$axios.post(nurseryApplyUrl, params);
      if (res.code === 200) {
        this.$toast("已提交申请");
        this.$router.push({
          name: "entrustChild",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.entrustChildList {
  min-height: 100%;
  background: #fafafa;
  .banner {
    height: 318px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .body {
    padding: 0 32px;
    .box {
      position: relative;
      margin-top: -40px;
      background: #fff;
      padding: 10px 14px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
      border-radius: 16px;
      .item {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 46px;
        font-size: 28px;
        font-weight: bold;
        color: #333333;
        padding: 32px 14px;
        &:not(:last-child) {
          border-bottom: 2px solid rgba(0, 0, 0, 0.06);
        }
        .item-l {
          min-width: 100px;
        }
        .item-r {
          flex: 1;
        }
        /deep/ .van-cell {
          padding: 0;
          input {
            text-align: right;
          }
        }
      }
      .time {
        padding: 26px 14px 30px;
        .title {
          font-size: 28px;
          font-weight: 600;
          color: #000000;
          line-height: 46px;
        }
        .time-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 30px 0 0;
          .item-l {
            font-size: 32px;
            font-weight: 400;
            color: #333333;
            line-height: 44px;
          }
          .item-r {
            width: 124px;
            height: 62px;
            background: #efefef;
            border-radius: 20px;
            font-size: 32px;
            font-weight: 600;
            color: #000000;
            line-height: 62px;
            text-align: center;
          }
        }
      }
    }
    .surebtn {
      margin-top: 78px;
      width: 100%;
      display: flex;
      justify-content: center;
      .btn {
        width: 550px;
        height: 66px;
        font-size: 28px;
        line-height: 66px;
        text-align: center;
        font-weight: bold;
        border-radius: 10px;
        background: linear-gradient(180deg, #ededed 0%, #d8d8d8 100%);
        color: #a7a7a7;
        &.light {
          color: #ffffff;
          background: linear-gradient(180deg, #ffc055 0%, #fe9514 100%);
        }
      }
    }
  }
}
.entrustChildList {
}
</style>
